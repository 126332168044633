<template>
  <v-container class="fill-height" fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="white--text primary py-2">
            <v-row dense>
              <v-col cols="12">
                <span class="headline mb-0">
                  Guias
                </span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <guias-table
              :valores="valoresTabla"
              :cargando="isLoading"
            ></guias-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapActions } from "vuex";
import GuiasTable from "@/components/guias/GuiasTable";

export default {
  name: "Guias",
  metaInfo: {
    title: 'Guias'
  },
  data: () => ({
    isLoading: true,
    componente: null,
    staticData: null,
    staticTipoGuia: null,
    staticZipcodes: null
  }),
  components: {
    GuiasTable
  },
  methods: {
    ...mapActions("guias", ["setGuiasAll"])
  },
  computed: {
    position() {
      let position = this.$vuetify.breakpoint.name;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "your position " + position;
        case "sm":
          return "your position " + position;
        case "md":
          return "your position " + position;
        case "lg":
          return "your position " + position;
        case "xl":
          return "your position " + position;
      }
      return "";
    },
    valoresTabla() {
      if (!this.staticData) {
        return [];
      }

      let salida = [];
      this.staticData.forEach(el => {
        const fecha = moment(el.created_at);

        const tmp = {
          id: el.id,
          zona: el.zona,
          tipo_guia: this.staticTipoGuia.find(t => t.id === el.tipo_guia_id)
            .tipo,
          tipo_guia_id: el.tipo_guia_id,
          agrupar_provincia: el.agrupar_provincia,
          visible_en_web: el.visible_en_web,
          fecha_alta: fecha.format("DD/MM/YYYY"),
          ccaas_count: el.ccaas_guia.length,
          ccaas: el.ccaas_guia,
          provincias_count: el.provincias_guia.length,
          provincias: el.provincias_guia,
          zipcodes_count: el.zipcodes_guia.length,
          zipcodes: el.zipcodes_guia
        };
        salida.push(tmp);
      });
      return salida;
    }
  },
  async created() {
    this.$store.commit("comun/SET_MAIN_LOADER", true);
    //  console.time();
    const res = await this.setGuiasAll();
    //this.staticZipcodes = res.zipcodes;
    this.staticData = _.cloneDeep(res.datos);
    this.staticTipoGuia = _.cloneDeep(res.tipoGuia);
    this.isLoading = false;
    this.$store.commit("comun/SET_MAIN_LOADER", false);
    //  console.timeEnd();
  },
  mounted() {}
};
</script>

<style scoped></style>
