<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        dense
        multi-sort
        :headers="headers"
        :items="valores"
        :expanded.sync="expanded"
        locale="es-es"
        :loading="cargando"
        loading-text="Cargando Datos... Espere"
        :items-per-page="10"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
          'items-per-page-options': [15, 30, 50, 100],
          'items-per-page-text': 'Filas por página'
        }"
      >
        <!-- datos del desplegable de cada registro -->
        <!--   <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <guia-detalle-list
              :datos="item"
              :hora="compDate"
              transition="fade-transition"
            ></guia-detalle-list>
          </td>
        </template> -->
        <!-- Agrupar -->
        <template v-slot:[`item.agrupar_provincia`]="{ item }">
          <v-chip label small :color="getColor(item.agrupar_provincia)" dark>{{
            item.agrupar_provincia === 1 ? "Sí" : "No"
          }}</v-chip>
        </template>
        <template v-slot:[`item.visible_en_web`]="{ item }">
          <v-chip label small :color="getColor(item.visible_en_web)" dark>{{
              item.visible_en_web === 1 ? "Sí" : "No"
            }}</v-chip>
        </template>
        <!-- slot ccaa -->
        <template v-slot:[`item.ccaas_count`]="{ item }">
          <v-chip label small color="red" dark>{{ item.ccaas_count }}</v-chip>
        </template>
        <!-- slot Provincias -->
        <template v-slot:[`item.provincias_count`]="{ item }">
          <v-chip label small color="indigo" dark>{{
            item.provincias_count
          }}</v-chip>
        </template>
        <!-- slot CodPostales -->
        <template v-slot:[`item.zipcodes_count`]="{ item }">
          <v-chip label small color="blue" dark>{{
            item.zipcodes_count
          }}</v-chip>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <!--           <v-tooltip bottom>
            <template v-slot:activator="{ on }">
               <v-icon
                small
                class="mr-2"
                color="red"
                @click="editar(item)"
                v-on="on"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>Editar</span> 
          </v-tooltip> -->

          <!-- boton para generar una guia -->
          <template v-show="!descargandoPDF">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-2"
                  color="blue"
                  @click="verPDF(item)"
                  v-on="on"
                  v-show="!descargandoPDF"
                >
                  mdi-pdf-box
                </v-icon>
              </template>
              <span>Descargar PDF</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  class="mr-2"
                  color="green"
                  @click="verPDF(item, false)"
                  v-on="on"
                  v-show="!descargandoPDF"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>Abrir PDF</span>
            </v-tooltip>
          </template>
          <!-- icono mientras se genera pdf -->

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon
                class="mr-2 descargando"
                color="red"
                v-on="on"
                v-show="descargandoPDF && actualDescPDF === item.id"
              >
                mdi-autorenew
              </v-icon>
            </template>
            <span>Generando PDF</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <!--     {{ pagination }} -->
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
// import GuiaDetalleList from "./forms/GuiaDetalleList";
//

export default {
  name: "Ficha-table",
  props: ["valores", "cargando"],
  components: {
    /*  GuiaDetalleList */
  },
  data: () => ({
    isLoading: true,
    descargandoPDF: false,
    actualDescPDF: null,
    expanded: [],
    pagination: {},
    headers: [
      {
        text: "id",
        align: " d-none",
        value: "id",
      },
      {
        text: "Zona",
        align: "left",
        value: "zona",
      },
      {
        text: "Tipo Guía",
        align: "left",
        value: "tipo_guia",
        width: "140",
      },
      {
        text: "Agrupar",
        align: "center",
        value: "agrupar_provincia",
        width: "100",
      },
      {
        text: "Visible en web",
        align: "center",
        value: "visible_en_web",
        width: "100",
      },
      /*   {
        text: "CCAAs",
        align: "right",
        value: "ccaas_count",
      },
      {
        text: "Provincias",
        align: "right",
        value: "provincias_count",
      },
      {
        text: "Cod Postales",
        align: "right",
        value: "zipcodes_count",
      }, */
      {
        text: "Fecha Alta",
        align: "center",
        value: "fecha_alta",
        width: "140",
      },
      {
        text: "Acciones",
        value: "action",
        align: "center",
        sortable: false,
        width: "140",
      },
      /*   { text: "Detalle", value: "data-table-expand" }, */
    ],
  }),
  methods: {
    ...mapActions("guias", ["setGuiaPdf"]),
    getColor(estado) {
      switch (estado) {
        case 1:
          return "green";
        case 2:
          return "red";
        default:
          return "grey";
      }
    },
    /* editar el form - Sin uso */
    /*  editar(item) {
      //this.$router.push({ name: "gdetalle", params: { id: item.id } });
    }, */
    /* descargar el pdf */
    async verPDF(item, descargar = true) {
      // eslint-disable-next-line no-console
      console.time("tiempo de creación pdf");
      this.actualDescPDF = item.id;
      this.descargandoPDF = true;
      const response = await this.setGuiaPdf(item.id);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', item.zona+'.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();

      this.descargandoPDF = false;
      this.actualDescPDF = null;
      // eslint-disable-next-line no-console
      console.timeEnd("tiempo de creación pdf");
    },
  },
  computed: {
    compDate() {
      return +new Date();
    },
  },
  watch: {},
  mounted() {
    // console.log(this.loadPagination);
    this.isLoading = false;
  },
};

const generarNombre = (zona) => {
  if (!zona) {
    return "";
  }
  const fecha = +new Date();
  const nombre = zona.trim().toLowerCase().replaceAll(" ", "_");
  const tmp = `${nombre}"_"${fecha}.pdf`;
  return tmp;
};
</script>

<style scoped>
.d-none {
  display: none !important;
}
::v-deep.v-data-table td {
  font-size: 0.9em;
  height: 28px;
}
::v-deep tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}
::v-deep.v-data-table tr:hover {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.v-icon.descargando {
  transform: rotate(-180deg);
  animation: rotar 0.5s infinite forwards;
}
@keyframes rotar {
  100% {
    transform: rotate(90deg);
  }
  75% {
    transform: translateY(180deg);
  }
  50% {
    transform: translateY(270deg);
  }
  0% {
    transform: translateY(0deg);
  }
}
</style>
